<template>
  <v-container style="height: 85vh">
    <v-row align="center" style="height: 100%" v-if="userLoggedIn">
      <v-col align="center">
        <v-icon style="font-size: 100px" color="msaBlue"> mdi-alert </v-icon>
        <h4 class="msaBlue--text font-weight-regular">
          This is probably not where you want to be.
        </h4>
        <h4 class="msaBlue--text font-weight-regular">
          Use the menu above to find your way back.
        </h4>
      </v-col>
    </v-row>
    <v-row align="center" style="height: 100%" v-else>
      <v-col align="center">
        <v-icon style="font-size: 100px" color="msaBlue"> mdi-alert </v-icon>
        <h4 class="msaBlue--text font-weight-regular">Access Denied.</h4>
        <v-btn
          class="msaBlue white--text mt-2"
          @click="$router.push({ name: 'UserLogin' })"
        >
          Login
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
export default {
  name: 'AccessDenied',
  computed: {
    userLoggedIn() {
      return this.$store.getters.user != '';
    },
  },
  data() {
    return {};
  },
};
</script>
